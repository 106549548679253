import { ref, watch, computed } from '@vue/composition-api';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default function useReportsList() {
  // Use toast
  const toast = useToast();

  const refReportListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'student', sortable: true },
    // { key: 'name', sortable: true },
    // { key: 'email', sortable: true },
    // { key: 'total', sortable: true, formatter: val => `$${val}` },
    { key: 'createdAt', sortable: true },
    { key: 'programName', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalReports = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);

  const statusFilter = ref(null);
  const pathwayFilter = ref(null);
  const createAtStartFilter = ref(null);
  const createAtEndFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refReportListTable.value ? refReportListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReports.value,
    };
  });

  const refetchData = () => {
    refReportListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, createAtStartFilter, createAtEndFilter, pathwayFilter, statusFilter], () => {
    refetchData();
  });

  const fetchReports = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,
    };

    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }
    if (pathwayFilter.value) {
      pl.programName = pathwayFilter.value;
    }
    if (createAtStartFilter.value) {
      pl.createAtStart = createAtStartFilter.value;
    }
    if (createAtEndFilter.value) {
      pl.createAtEnd = createAtEndFilter.value;
    }

    store
      .dispatch('reportStoreModule/fetchReports', pl)
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(results);
        totalReports.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching reports' list-pending",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveReportStatusVariantAndIcon = (status) => {
    if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' };
    if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' };
    if (status === 'Downloaded') return { variant: 'primary', icon: 'ArrowDownCircleIcon' };
    if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' };
    if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' };
    if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' };
    return { variant: 'secondary', icon: 'XIcon' };
  };

  const resolveReportStatusVariant = (status) => {
    if (status === 'terminated') return 'danger';
    if (status === 'withdrew') return 'warning';
    if (status === 'graduated') return 'success';
    if (status === 'inactive') return 'secondary';
    return 'info';
  };

  const resolveClientAvatarVariant = (status) => {
    if (status === 'Partial Payment') return 'info';
    if (status === 'Paid') return 'danger';
    if (status === 'Downloaded') return 'secondary';
    if (status === 'Draft') return 'warning';
    if (status === 'Sent') return 'info';
    if (status === 'Past Due') return 'success';
    return 'info';
  };

  const statusOptions = [
    { label: 'Graduated', value: 'graduated' },
    { label: 'Terminated', value: 'terminated' },
    { value: 'withdrew', label: 'Withdrew' },
  ];
  const pathwayOptions = [
    { label: 'CompTIA Security+', value: 'CompTIA Security+' },
    { label: 'DevOps Pathway', value: 'DevOps Pathway' },
  ];

  return {
    fetchReports,
    tableColumns,
    perPage,
    currentPage,
    totalReports,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReportListTable,

    statusFilter,
    pathwayFilter,
    createAtStartFilter,
    createAtEndFilter,

    resolveReportStatusVariantAndIcon,
    resolveReportStatusVariant,
    resolveClientAvatarVariant,

    refetchData,
    statusOptions,
    pathwayOptions,
  };
}
