<template>
  <div class="container content-p">
    <!-- Filters -->
    <reports-list-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      :pathway-filter.sync="pathwayFilter"
      :pathway-options="pathwayOptions"
      :create-at-start-filter.sync="createAtStartFilter"
      :create-at-end-filter.sync="createAtEndFilter"
    />

    <!-- Table Container Card -->
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Entries</label>
            <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
            <b-button variant="primary" :to="{ name: 'apps-report-add' }"> Add Record </b-button>
            <b-button variant="info" :to="{ name: 'apps-report-import' }" class="ml-1" :disabled="processing"> Import XLSX </b-button>

            <b-button variant="secondary" @click="doSelectedPdfExport" v-if="selected.length > 0" class="ml-1" :disabled="processing">
              Download ZIP
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <v-select
                v-if="false"
                v-model="statusFilter"
                :options="statusOptions"
                class="report-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
          <b-col cols="12" md="2">
            <b-button variant="primary" @click="doAllPdfExport" class="w-100" :disabled="processing"> Mass - ZIP </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refReportListTable"
        :items="fetchReports"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        selectable
        select-mode="range"
        @row-selected="onRowSelected"
      >
        <template #head(reportStatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link :to="{ name: 'apps-report-preview', params: { id: data.item.id } }" class="font-weight-bold">
            {{ data.value }}
          </b-link>
        </template>

        <!-- Column: Student Id -->
        <template #cell(reportStatus)="data">
          <b-avatar
            :id="`report-row-${data.item.id}`"
            size="32"
            :variant="`${resolveReportStatusVariantAndIcon(data.item.reportStatus).variant}`"
          >
            <feather-icon :icon="resolveReportStatusVariantAndIcon(data.item.reportStatus).icon" />
          </b-avatar>
          <b-tooltip :target="`report-row-${data.item.id}`" placement="top">
            <p class="mb-0">
              {{ data.item.reportStatus }}
            </p>
            <p class="mb-0">Balance: {{ data.item.balance }}</p>
            <p class="mb-0">Due Date: {{ data.item.dueDate }}</p>
          </b-tooltip>
        </template>

        <!-- Column: Student -->
        <template #cell(student)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :text="avatarText(data.item.name)" :variant="`light-${resolveClientAvatarVariant(data.item.status)}`" />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.name }}
            </span>
            <small class="text-muted">{{ data.item.myIpId }}</small>
          </b-media>
        </template>

        <!-- Column: Created At -->
        <template #cell(createdAt)="data">
          <span class="text-nowrap">
            {{ moment(data.value).format('L') }}
          </span>
        </template>

        <!-- Column: Program Name -->
        <template #cell(programName)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge :variant="`${resolveReportStatusVariant(data.value)}`" class="text-capitalize">
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: Balance -->
        <template #cell(balance)="data">
          <template v-if="data.value === 0">
            <b-badge variant="light-success"> Paid </b-badge>
          </template>
          <template v-else>
            {{ data.value }}
          </template>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon :id="`report-row-${data.item.id}-send-icon`" icon="SendIcon" class="cursor-pointer" size="16" />
            <b-tooltip title="Send Report" class="cursor-pointer" :target="`report-row-${data.item.id}-send-icon`" />

            <feather-icon
              :id="`report-row-${data.item.id}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1"
              @click="$router.push({ name: 'apps-report-preview', params: { id: data.item.id } })"
            />
            <b-tooltip title="Preview Report" :target="`report-row-${data.item.id}-preview-icon`" />

            <!-- Dropdown -->
            <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item v-if="false">
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Download</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'apps-report-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalReports"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import vSelect from 'vue-select';
import { onUnmounted } from '@vue/composition-api';
import store from '@/store';
import useReportsList from './useReportList';
import ReportsListFilters from './ReportsListFilters';
import moment from 'moment';

import reportStoreModule from '../reportStoreModule';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    ReportsListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    doSelectedPdfExport() {
      this.processing = true;
      this.doExport(this.selected.map((a) => a.id));
    },

    doExport(ids) {
      this.$http
        .post(`v1/jobs/export`, { entity: 'report', metadata: { ids } })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,

            props: {
              title: `Job Started`,
              icon: 'SettingsIcon',
              variant: 'success',
              text: `Your job has been queued for execution. You may leave the site while it is prepared.`,
            },
          });

          this.$router.push({ name: 'apps-job-list-pending' });
        })
        .catch((e) => {
          this.processing = false;
          console.error(e);
        });
    },

    async selectNextPage(page, payload) {
      return new Promise((resolve, reject) => {
        store
          .dispatch('reportStoreModule/fetchReports', { ...payload, page: page })
          .then(resolve)
          .catch(reject);
      });
    },
    async doAllPdfExport() {
      this.processing = true;
      try {
        this.$toast({
          component: ToastificationContent,

          props: {
            title: `Job Build Started`,
            icon: 'SettingsIcon',
            variant: 'success',
            text: `This may take a moment to generate the job settings, please wait...`,
          },
        });

        const { searchQuery, statusFilter, pathwayFilter, createAtStartFilter, createAtEndFilter } = this;

        const pl = {
          search: searchQuery,
          limit: 100,
        };

        if (statusFilter) {
          pl.status = statusFilter;
        }
        if (pathwayFilter) {
          pl.programName = pathwayFilter;
        }
        if (createAtStartFilter) {
          pl.createAtStart = createAtStartFilter;
        }
        if (createAtEndFilter) {
          pl.createAtEnd = createAtEndFilter;
        }

        let currentPage = 1;
        let lastPage = 2;

        let allIds = [];
        let currentFail = 0;

        while (currentPage <= lastPage || currentFail > 5) {
          try {
            const response = await this.selectNextPage(currentPage, pl);

            if (response.status !== 200) {
              currentPage -= 1;
              currentFail += 1;
              continue;
            }

            let { results, totalPages } = response.data;

            console.log(results);
            results.forEach((report) => allIds.push(report.id));

            lastPage = totalPages;
            currentPage += 1;
            currentFail = 0;
          } catch (e) {
            currentFail += 1;
            currentPage -= 1;
          }
        }

        allIds = allIds.filter((value, index, self) => self.indexOf(value) === index);

        this.doExport(allIds);
      } catch (e) {
        console.error(e);
      }

      this.processing = false;
    },
  },
  data: () => {
    return {
      selected: [],
      processing: false,
      vm: undefined,
    };
  },
  mounted() {
    this.vm = this;
  },
  setup() {
    const {
      statusOptions,
      fetchReports,
      tableColumns,
      perPage,
      currentPage,
      totalReports,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refReportListTable,

      createAtStartFilter,
      createAtEndFilter,

      statusFilter,

      pathwayFilter,
      pathwayOptions,

      refetchData,

      resolveReportStatusVariantAndIcon,
      resolveReportStatusVariant,
      resolveClientAvatarVariant,
    } = useReportsList();

    return {
      fetchReports,
      tableColumns,
      perPage,
      currentPage,
      totalReports,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refReportListTable,

      createAtStartFilter,
      createAtEndFilter,

      statusFilter,
      statusOptions,

      pathwayFilter,
      pathwayOptions,

      refetchData,

      avatarText,
      resolveReportStatusVariantAndIcon,
      resolveReportStatusVariant,
      resolveClientAvatarVariant,
      moment,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.report-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
